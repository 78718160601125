<template>
  <div>
    <div>
      <div class="row">
        <div class="col-12 mx-auto">
          <div class="card card-custom gutter-b example example-compact">
            <div
              class="
                card-header
                d-flex
                align-items-center
                justify-content-between
              "
            >
              <div class="card-title">
                <h3 class="card-label">Kassa</h3>
              </div>
              <div class="card-button">
                <create />
              </div>
            </div>
            <div class="card-body">
              <v-data-table
                :headers="headers"
                :items="list"
                :items-per-page="10"
                hide-default-footer
              >
                <template v-slot:[`item.amountDebet`]="{ item }">
                  <div v-if="item.order_type_code == '01'">
                    {{ item.amount.toLocaleString('zh-ZH') }}
                  </div>
                </template>
                <template v-slot:[`item.amount`]="{ item }">
                  <div v-if="item.order_type_code == '02'">
                    {{ item.amount.toLocaleString('zh-ZH') }}
                  </div>
                </template>

                <template v-slot:[`item.oper_date`]="{ item }">
                  {{ item.oper_date | formatDate }}
                </template>

                <template v-slot:[`item.action`]="{ item }">
                  <div style="text-align: right !important">
                    <action :index="item"></action>
                  </div> </template
              ></v-data-table>
            </div>
            <div class="text-left my-5">
              <v-pagination
                v-model="currentPage"
                :total-visible="7"
                @input="getPostData(currentPage)"
                :length="Math.ceil(getCount.count / 10)"
              ></v-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import action from './actionDetails'
// import create from "./create";
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
export default {
  components: { action },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Turi', value: 'order_type_name' },
        { text: 'Kirim', value: 'amountDebet' },
        { text: 'Chiqimi', value: 'amount' },
        { text: 'Sana', value: 'oper_date' },
        { text: 'Transfer nomi', value: 'type_statetransfer_name' },
        { text: 'Yaratilgan sana', value: 'created_at' },
        { text: '', value: 'action', sortable: false }
      ]
    }
  },
  created() {
    // this.$store.dispatch("kontragentContractAktSverka");
    if (this.$route.query.page !== undefined) {
      this.getPostData(parseInt(this.$route.query.page, 10))
      this.currentPage = parseInt(this.$route.query.page, 10)
    } else {
      this.getPostData(this.currentPage)
    }
  },
  filters: {
    formatDate: function (val) {
      if (val !== null) {
        return val.split('-').reverse().join('-')
      }
    }
  },
  methods: {
    getPostData(value) {
      if (parseInt(this.$router.currentRoute.query.page, 10) !== value) {
        this.$router.push({
          path: this.$router.currentRoute.path,

          query: { page: value }
        })
      }
      const data = {
        page: value,
        payment_source: this.$route.params.source,
        oper_date: this.$route.params.date
      }
      this.$store.dispatch('TransactionsDailyDetails', data)
    }
  },
  computed: {
    list() {
      const data = this.$store.state.requests.transactionsDailyDetails.results
      return data
    },
    getCount() {
      return this.$store.state.requests.transactionsDailyDetails
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Moliya' },
      { title: 'Vipiska' }
    ])
  }
}
</script>
